import { Footer } from "../../pages/footer";
import { Header } from "../Header";
import { Container } from "./styles";

interface IPropsLayout {
  children: React.ReactNode;
}

export const Layout = ({ children }: IPropsLayout) => {
  return (
    <Container>
      <Header />
      <main>{children}</main>
      <div className="whatsapp-icon">
        <a
          href="https://wa.me/13991445362?text=Ol%C3%A1,%20gostaria%20de%20tirar%20uma%20d%C3%BAvida."
          target="_blank"
          className="icon-whats-fixed"
          title="Entrar em contato"
          rel="noreferrer"
        >
          <img
            loading="lazy"
            src="whatsapp.webp"
            alt="WhatsApp"
            className="icon-whats-img lazy-load"
          />
        </a>
      </div>
    </Container>
  );
};
