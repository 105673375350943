export const LivroPt = [
  {
    _id: "672ef457f128dcc6286cbdb3",
    title: "O Poder Da Mente Plena",
    subtitle: "",
    description:
      "Se existisse uma habilidade que você pudesse aprender e que melhorasse sua vida, qual seria? Sem dúvidas, seria a habilidade de controlar suas emoções e sua forma de pensar",
    type: "baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/XNHgtThd/capa-mente.jpg",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "67342587044832b3c54aa0f3",
    title: "Pai rico Pai pobre",
    subtitle: "",
    description:
      "Pai Rico, Pai Pobre ensina a importância da educação financeira, comparando duas visões sobre dinheiro. O livro destaca a necessidade de investir e adquirir ativos",
    type: "baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/fyrjX9XB/capa-pai-rico.jpg",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "67343154044832b3c54aa121",
    title: "O Poder do Hábito",
    subtitle: "",
    description:
      "O Poder do Hábito explica como os hábitos funcionam e como podemos modificá-los, entendendo o ciclo de dica, rotina para alcançar mudanças positivas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/5tSBgGLH/capa-pode-habito.jpg",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "67343208044832b3c54aa12c",
    title: "Os Segredos da Mente Milionária",
    subtitle: "",
    description:
      "Ensina a mudar a mentalidade sobre dinheiro, com 17 princípios que diferenciam a visão dos ricos e dos pobres",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/HnRXjCDN/capa-seg-mente.jpg",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "67343316044832b3c54aa136",
    title: "Como aumentar seu salário",
    subtitle: "",
    description:
      "Como Aumentar Seu Próprio Salário ensina como aplicar princípios de sucesso, como iniciativa e liderança, para aumentar a renda e se destacar no mercado",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/pTBs9rq6/capa-aumenta-sa.webp",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "673433b5044832b3c54aa140",
    title: "Como Fazer Amigos e Influenciar Pessoas",
    subtitle: "",
    description:
      "Influenciar Pessoas ensina técnicas para melhorar sua comunicação",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/44B8sKKK/capa-fazer-ami.jpg",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "67343567044832b3c54aa147",
    title: "Como Estudar Para Vencer",
    subtitle: "",
    description:
      "Como Estudar de forma estratégica para otimizar o desempenho acadêmico, focando em organização, concentração e memorização",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/BbYNgyW5/capa-estudar-ve.jpg",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "673435a9044832b3c54aa14a",
    title: "Quero Ficar Rico",
    subtitle: "",
    description:
      "Quero Ficar Rico é um guia prático que ensina a alcançar a riqueza por meio da educação financeira",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/ZRfrtdf4/capa-quero-fic.jpg",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "673435df044832b3c54aa14d",
    title: "Como Ser um Ímã para o Dinheiro",
    subtitle: "",
    description:
      "Ensina a atrair riqueza por meio de pensamentos positivos, transformando crenças limitantes em hábitos de abundância",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/3wXB6W5x/capa-ima-din.jpg",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "67343614044832b3c54aa150",
    title: "QI de Persuasão",
    subtitle: "",
    description:
      "QI de Persuasão explora princípios psicológicos da persuasão e como usá-los para melhorar a comunicação e a eficácia em vendas, negociações e relacionamentos pessoais",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/2yVnzSJv/capa-qi-pers.jpg",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "67343643044832b3c54aa153",
    title: "Palavras que tem milhões",
    subtitle: "",
    description:
      "Palavras que Têm Milhões revela o poder transformador das palavras na vida e nas relações, destacando a importância da comunicação eficaz",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/L6G38jms/capa-pala-vend.jpg",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "673436ca044832b3c54aa156",
    title: "As 48 Leis do poder",
    subtitle: "",
    description:
      "As 48 Leis do Poder, apresenta estratégias para conquistar, manter e usar o poder, baseadas em exemplos históricos e táticas de manipulação e controle",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/XYZ12VYr/capa-48-leis.jpg",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "6734370e044832b3c54aa159",
    title: "Poder e manipulação",
    subtitle: "",
    description:
      "Poder e Manipulação, explora como o poder é exercido por meio de táticas de manipulação e controle social",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/DZfqMgPx/capa-poder-mani.jpg",
    category: "Persuasao",
    language: "Portuguese",

    urlAudio: "hzznneZQj6E&t=2s",
  },
  {
    _id: "67343743044832b3c54aa15c",
    title: "Persuasão e Gatilhos Mentais",
    subtitle: "",
    description:
      "Persuasão e Gatilhos Mentais, desvenda os princípios psicológicos por trás da persuasão e como gatilhos mentais podem ser utilizados para influenciar decisões e comportamentos",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/FK4bjmd5/capa-persua.jpg",
    category: "Persuasao",
    language: "Portuguese",

    urlAudio: "xKXrqYIySs&t=5s",
  },
  {
    _id: "67343769044832b3c54aa15f",
    title: "Técnicas proibidas de persuasão",
    subtitle: "",
    description:
      "Técnicas de persuasão, revela métodos avançados e controversos para influenciar pessoas de maneira eficaz, explorando os limites éticos da manipulação psicológica",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/m217Jh7W/capa-tec-pers.jpg",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "6734379a044832b3c54aa162",
    title: "A arte da persuasão",
    subtitle: "",
    description:
      "A Arte da Persuasão, ensina como influenciar pessoas de forma sutil e eficaz, utilizando técnicas de comunicação, linguagem corporal e psicologia",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/nrc0qxWc/capa-arte-mani.jpg",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "673437e6044832b3c54aa165",
    title: "27 poderes da persuasão",
    subtitle: "",
    description:
      "27 Poderes da Persuasão Hilaire, apresenta técnicas práticas e poderosas para influenciar e convencer pessoas em diversas situações",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/rFwPrG6C/capa-27-persua.jpg",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "67343816044832b3c54aa168",
    title: "As armas da persuasão",
    subtitle: "",
    description:
      "As Armas da Persuasão revela os princípios psicológicos que influenciam a tomada de decisões, mostrando como pequenas estratégias podem gerar um impacto poderoso na persuasão",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/mrCXs8MR/capa-armas.webp",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "6734383e044832b3c54aa16b",
    title: "Manual de Persuasão do FBI",
    subtitle: "",
    description:
      "Manual de Persuasão do FBI explora técnicas práticas de negociação e influência usadas pelo FBI para lidar com situações de alta pressão e convencer pessoas em contextos críticos",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/C51szNZR/capa-manual.webp",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "6735409aa5ef7ef3132c0a7a",
    title: "Do Mil ao Milhão",
    subtitle: "",
    description:
      "Do Mil ao Milhão de Thiago Nigro ensina como alcançar a independência financeira através de educação financeira, controle de gastos e investimentos inteligentes, com foco em disciplina e consistência no longo prazo.",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/XJh37Zdf/do-mil-ao-mi.png",
    category: "Rico",
    language: "Portuguese",
  },
  {
    _id: "673543ffa5ef7ef3132c0a7e",
    title: "Código da Inteligência",
    subtitle: "",
    description:
      "Código da Inteligência de Augusto Cury ensina a melhorar o controle emocional, a autoestima e a qualidade do pensamento, oferecendo técnicas para lidar com padrões negativos e aprimorar a tomada de decisões",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Hs0x32bF/codigo-intel.jpg",
    category: "Persuasao",
    language: "Portuguese",
  },
  {
    _id: "67394cb17a4acc1e3b03a2f4",
    title: "A Psicologia das Emoções",
    description:
      "A Psicologia das Emoções de Paul Ekman explora como as emoções são expressas no rosto e no corpo, abordando sentimentos universais e seu impacto nas interações sociais e no comportamento humano",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/5NhztVzv/piscicologia-emoc.webp",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "SY9EeYv-Yjc",
  },
  {
    _id: "67394cb17a4acc1e3b03a2f5",
    title: "Comece pelo Porquê",
    description:
      "Comece pelo Porquê, de Simon Sinek, defende que líderes e empresas de sucesso começam com um propósito claro o porquê antes de se preocupar com o como e o quê. Isso inspira confiança, lealdade e motivação nas pessoas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/X7qMGdwS/comece-po.jpg",
    category: "Liderança e Sucesso",
    language: "Portuguese",
    urlAudio: "E3ndMviTVpI",
  },
  {
    _id: "67394cb17a4acc1e3b03a2f6",
    title: "Criação de Riqueza",
    description:
      "Criação de Riqueza, de Paulo Vieira, ensina a mudar a mentalidade e os hábitos para alcançar prosperidade. O autor destaca a importância do autoconhecimento, disciplina e inteligência emocional para criar riqueza de forma equilibrada e duradoura",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/P5bMdFPt/criacao-ri.jpg",
    category: "Empreendedorismo e Negócios",
    language: "Portuguese",
    urlAudio: "M9BXFx4GnFw",
  },
  {
    _id: "67394cb17a4acc1e3b03a2f7",
    title: "Arrume Sua Cama",
    description:
      "Arrume Sua Cama, de William H. McRaven, ensina que pequenas ações diárias, como arrumar a cama, cultivam disciplina e resiliência, preparando-nos para enfrentar desafios maiores com determinação e coragem",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/kGn2Ff7y/arrume-su.jpg",
    category: "Produtividade e Gestão de Tempo",
    language: "Portuguese",
    urlAudio: "UO4YQyrahHQ",
  },
  {
    _id: "67394cb17a4acc1e3b03a2f8",
    title: "Faça Seu Cérebro Trabalhar por Você",
    description:
      "Faça Seu Cérebro Trabalhar por Você, de Renato Alves, ensina como otimizar a memória, o foco e a produtividade através de técnicas práticas para treinar a mente e melhorar o desempenho cerebral no dia a dia",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/j2FRwX22/faca-seu.jpg",
    category: "Produtividade e Gestão de Tempo",
    language: "Portuguese",
    urlAudio: "PV2QNGFpQkY",
  },
  {
    _id: "67394cb17a4acc1e3b03a2f9",
    title: "Scrum A Arte de Fazer o Dobro do Trabalho na Metade do Tempo",
    description:
      "Scrum A Arte de Fazer o Dobro do Trabalho na Metade do Tempo, de Jeff Sutherland, mostra como o Scrum aumenta a produtividade, otimizando processos e eliminando desperdícios. O livro explica os princípios do Scrum, como sprints, feedback constante e equipes auto-organizadas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/4x5B6NpC/scrum-a.jpg",
    category: "Empreendedorismo e Criação de Riqueza",
    language: "Portuguese",
    urlAudio: "7crOdqQuaRw",
  },
  {
    _id: "67394cb17a4acc1e3b03a2fa",
    title: "Bora Vender",
    description:
      "Bora Vender , O autor destaca a importância de desenvolver uma mentalidade proativa e persistente, essencial para alcançar resultados",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/wjJcHj3S/bora-vender.jpg",
    category: "Empreendedorismo e Negócios",
    language: "Portuguese",
    urlAudio: "-7ycBmHL2p8",
  },
  {
    _id: "67394cb17a4acc1e3b03a2fb",
    title: "A Menina que Roubava Livros",
    description:
      "A Menina Que Roubava Livros é uma narrativa contada pela Morte, que observa os acontecimentos com uma perspectiva única. Liesel faz amizades profundas, como com o judeu Max, que se esconde na casa dos Hubermann",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/qRdJt0ch/a-menina.jpg",
    category: "Literatura e Ficção",
    language: "Portuguese",
    urlAudio: "1jzUgWcpCVE",
  },
  {
    _id: "67394cb17a4acc1e3b03a2fc",
    title: "Bilionários",
    description:
      "Bilionários de Ricardo Geromel discute o poder e a influência desses bilionários na política, na economia e na desigualdade social",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/T1fnJzxh/bilio-na.jpg",
    category: "Empreendedorismo e Negócios",
    language: "Portuguese",
    urlAudio: "f0Or5Ix61YM",
  },
  {
    _id: "67394cb17a4acc1e3b03a2fd",
    title: "A Arte da Guerra",
    description:
      "A Arte da Guerra é um clássico tratado militar destinado a Sun Tzu, um estrategista militar da Antiguidade, que oferece princípios e táticas sobre como vencer guerras e conflitos",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/wBRrCmmL/a-arte.jpg",
    category: "Liderança e Sucesso",
    language: "Portuguese",
    urlAudio: "WcG9k3hxuDg",
  },
  {
    _id: "67394cb17a4acc1e3b03a2fe",
    title: "Uma Breve História do Tempo",
    description:
      "Uma Breve História do Tempo de Stephen Hawking é uma introdução à cosmologia e à física teórica, explicando conceitos complexos de forma acessível",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/FKgrLzrt/uma-breve.jpg",
    category: "Filosofia e Reflexão",
    language: "Portuguese",
    urlAudio: "oDkIwopd7SI",
  },
  {
    _id: "67394cb17a4acc1e3b03a2ff",
    title: "Trabalhe 4 Horas por Semana",
    description:
      "Trabalhe 4 Horas por Semana de Timothy Ferriss é um guia para repensar o trabalho e alcançar mais liberdade pessoal e financeira. O livro propõe um modelo de vida baseado em produtividade inteligente e fontes de renda passiva, permitindo que se trabalhe menos e viva mais",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/JnCWPLhN/trabalhe-4horas.jpg",
    category: "Produtividade e Gestão de Tempo",
    language: "Portuguese",
    urlAudio: "Q5GrvZKANXY",
  },
  {
    _id: "67394cb17a4acc1e3b03a300",
    title: "Ainda Dá!",
    description:
      "Ainda Dá! é um livro do filósofo e educador Mario Sergio Cortella, publicado em 2020. A obra traz reflexões sobre a importância de continuar a lutar pelos nossos objetivos, mesmo diante das adversidades e incertezas da vida",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/k4MPkyxG/ainda-da.jpg",
    category: "Produtividade e Gestão de Tempo",
    language: "Portuguese",
    urlAudio: "U6IbjLcZAl4",
  },
  {
    _id: "67394cb17a4acc1e3b03a301",
    title:
      "Você Mais Inteligente – Técnicas de Gestão da Emoção para Revolucionar Sua Vida",
    description:
      "Você Mais Inteligente – Técnicas de Gestão da Emoção para Revolucionar Sua Vida de Augusto Cury é um livro que ensina como desenvolver a inteligência emocional para melhorar a qualidade de vida",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/RhQs4r4z/voce-mais.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "VKHTv9ek1Ww",
  },
  {
    _id: "67394cb17a4acc1e3b03a302",
    title: "As 16 Leis do Sucesso",
    description:
      "As 16 Leis do Sucesso, de Napoleon Hill, são princípios fundamentais que ele acredita serem essenciais para alcançar o sucesso. Essas leis formam um conjunto de princípios interconectados, voltados para o desenvolvimento pessoal e profissional",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Pq5pzJyw/16-leis.jpg",
    category: "Liderança e Sucesso",
    language: "Portuguese",
    urlAudio: "k6dNtZggA5o",
  },
  {
    _id: "67394cb17a4acc1e3b03a303",
    title: "Alcançando Excelência em Vendas SPIN Selling",
    description:
      "O livro Alcançando Excelência em Vendas SPIN Selling, de Neil Rackham, apresenta a metodologia SPIN, que foca em um processo consultivo de vendas, ideal para transações complexas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/3NnzGLxp/alcancando-ex.jpg",
    category: "Liderança e Sucesso",
    language: "Portuguese",
    urlAudio: "PYk-KezEetM",
  },
  {
    _id: "67394cb17a4acc1e3b03a304",
    title: "Bitcoin A Moeda na Era Digital",
    description:
      "Bitcoin A Moeda na Era Digital, de Fernando Ulrich, é um livro que explora de forma acessível e detalhada o conceito do Bitcoin, a primeira criptomoeda, e o impacto que ela tem no sistema financeiro global",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/15Fd0c5L/bitcoin.jpg",
    category: "Finanças e Investimentos",
    language: "Portuguese",
    urlAudio: "YqtgfA4Hg7U",
  },
  {
    _id: "67394cb17a4acc1e3b03a305",
    title:
      "Do Zero ao Milhão: Como Transformar Seu Sonho em um Negócio Milionário",
    description:
      "Do Zero ao Milhão: Como Transformar Seu Sonho em um Negócio Milionário, de Carlos Wizard Martins, é um livro que busca inspirar e orientar empreendedores a transformar suas ideias e sonhos em negócios de sucesso",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/MTqSj3SZ/do-zero.jpg",
    category: "Empreendedorismo e Negócios",
    language: "Portuguese",
    urlAudio: "PXBUdBrjKEM",
  },
  {
    _id: "67394cb17a4acc1e3b03a306",
    title: "Desbloqueie o Poder da Sua Mente",
    description:
      "Desbloqueie o Poder da Sua Mente, de Michael Arruda, é um livro de autodesenvolvimento que ensina técnicas para liberar o potencial da mente e alcançar objetivos pessoais e profissionais",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/4dJD3K6m/desbloqueie-o.jpg",
    category: "Empreendedorismo e Criação de Riqueza",
    language: "Portuguese",
    urlAudio: "6Lu7Wq999lc",
  },
  {
    _id: "67394cb17a4acc1e3b03a307",
    title: "Salomão: O Homem Mais Rico Que Já Existiu",
    description:
      "Salomão: O Homem Mais Rico Que Já Existiu, de Steven K. Scott, é um livro que extrai lições de vida e sabedoria financeira dos ensinamentos do Rei Salomão, reconhecido por sua riqueza, sabedoria e liderança",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/gcfQQBxM/salomao.jpg",
    category: "Empreendedorismo e Criação de Riqueza",
    language: "Portuguese",
    urlAudio: "XQQE-yfUCsQ",
  },
  {
    _id: "67394cb17a4acc1e3b03a308",
    title: "A Revolução 80/20: O Poder da Escolha",
    description:
      "A obra A Revolução 80/20: O Poder da Escolha, de Richard Koch, é uma adaptação do princípio de Pareto, popularmente conhecido como A Lei 80/20, e aplica essa ideia ao mundo dos negócios, da vida pessoal e da gestão",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/QxkTC795/a-revolucao.jpg",
    category: "Empreendedorismo e Criação de Riqueza",
    language: "Portuguese",
    urlAudio: "8jqVEEvh6nY",
  },
  {
    _id: "67394cb17a4acc1e3b03a309",
    title: "A Ciência do Sucesso",
    description:
      "A Ciência do Sucesso de Napoleon Hill é um guia para alcançar grandes realizações, baseado em princípios de pensamento positivo e ações estratégicas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/y8GY86Pc/ciencia-do.jpg",
    category: "Empreendedorismo e Criação de Riqueza",
    language: "Portuguese",
    urlAudio: "awcqp_VbxlA",
  },
  {
    _id: "67394cb17a4acc1e3b03a30a",
    title: "40 Hábitos Financeiros Para Uma Vida Melhor",
    description:
      "O livro 40 Hábitos Financeiros Para Uma Vida Melhor propõe práticas para melhorar a saúde financeira e alcançar uma vida mais equilibrada e tranquila",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/T2QxPY21/40-habitos.jpg",
    category: "Finanças e Investimentos",
    language: "Portuguese",
    urlAudio: "BJSz8NF6IN0",
  },
  {
    _id: "67394cb17a4acc1e3b03a30b",
    title: "Desperte o Gigante Interior",
    description:
      "Desperte o Gigante Interior, de Anthony Robbins, é um guia de desenvolvimento pessoal que ensina como tomar controle da vida, das emoções e das ações para alcançar o sucesso",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/hj1XvWhn/desperte-gigante.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "FjK--qFV5RU",
  },
  {
    _id: "67394cb17a4acc1e3b03a30c",
    title:
      "A Mente Acima do Dinheiro: O Impacto das Emoções em Sua Vida Financeira",
    description:
      "A Mente Acima do Dinheiro: O Impacto das Emoções em Sua Vida Financeira explora como as emoções e crenças moldam as decisões financeiras das pessoas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/nhTZXFG0/a-mente.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "jqmKMdO1wR0",
  },
  {
    _id: "67394cb17a4acc1e3b03a30d",
    title: "A Marca da Vitória: A Autobiografia do Criador da Nike",
    description:
      "A Marca da Vitória, Shoe Dog, é a autobiografia de Phil Knight, fundador da Nike. O livro narra sua jornada desde a criação da Nike, inicialmente chamada Blue Ribbon Sports, até seu crescimento como uma das maiores marcas de artigos esportivos do mundo",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/26cSVgPL/a-marca.jpg",
    category: "Empreendedorismo e Negócios",
    language: "Portuguese",
    urlAudio: "F9Xxs9ANG7k",
  },
  {
    _id: "67394cb17a4acc1e3b03a30e",
    title: "Casais Inteligentes Enriquecem Juntos",
    description:
      "Casais Inteligentes Enriquecem Juntos de Gustavo Cerbasi ensina casais a melhorar sua vida financeira por meio de comunicação aberta, alinhamento de objetivos e planejamento conjunto",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/D01fBmSL/casais-inte.jpg",
    category: "Empreendedorismo e Criação de Riqueza",
    language: "Portuguese",
    urlAudio: "t4_28l0v1rA",
  },
  {
    _id: "67394cb17a4acc1e3b03a30f",
    title: "A Chave para a Prosperidade",
    description:
      "A Chave para a Prosperidade de Napoleon Hill ensina princípios para alcançar o sucesso e a prosperidade. Hill enfatiza a importância de uma mentalidade positiva, foco em objetivos claros, autossugestão e perseverança",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/TPnk265Q/a-chave.jpg",
    category: "Empreendedorismo e Criação de Riqueza",
    language: "Portuguese",
    urlAudio: "ty6hutEG6aY",
  },
  {
    _id: "67394cb17a4acc1e3b03a310",
    title: "A Lei da Atração e o Poder do Pensamento",
    description:
      "A Lei da Atração e o Poder do Pensamento de William Walker Atkinson é um livro que explora os princípios da Lei da Atração, que sugere que nossos pensamentos e crenças podem influenciar e atrair experiências e resultados em nossas vidas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/BtWLndKc/a-lei-da.jpg",
    category: "Finanças e Investimentos",
    language: "Portuguese",
    urlAudio: "dnxqJPJBr-A",
  },
  {
    _id: "67394cb17a4acc1e3b03a311",
    title: "As 15 Leis do Crescimento",
    description:
      "As 15 Leis do Crescimento de John C. Maxwell apresenta princípios para o crescimento pessoal e profissional. Ele destaca que o crescimento é um processo contínuo e que exige esforço diário, escolhas conscientes, reflexão e comprometimento",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/GmLYTnfq/as-15.jpg",
    category: "Liderança e Sucesso",
    language: "Portuguese",
    urlAudio: "_plbn3lxLhg",
  },
  {
    _id: "67394cb17a4acc1e3b03a312",
    title: "A Cabana",
    description:
      "A Cabana de William P. Young conta a história de Mackenzie Phillips, um homem que, após a tragédia do assassinato de sua filha, fica devastado e questiona sua fé",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/FsssjF9d/a-cabana.jpg",
    category: "Literatura e Ficção",
    language: "Portuguese",
    urlAudio: "lEsNboaG5FU",
  },
  {
    _id: "67394cb17a4acc1e3b03a313",
    title: "Ciência para Ficar Rico",
    description:
      "Ciência para Ficar Rico de Wallace D. Wattles ensina que a riqueza pode ser alcançada através de princípios específicos e consistentes. O autor defende que, para atrair riqueza, é necessário ter um pensamento criativo",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/hPjcXCrV/a-ciencia.jpg",
    category: "Finanças e Investimentos",
    language: "Portuguese",
    urlAudio: "qITHhKGqm1Q",
  },
  {
    _id: "67394cb17a4acc1e3b03a314",
    title: "Ocupado Demais Para Ler Este Livro",
    description:
      "Ocupado Demais Para Ler Este Livro de Tony Crabbe aborda como a sobrecarga de tarefas e a sensação constante de estar ocupado afetam nossa produtividade e bem-estar",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/CKWsq6k5/ocupado-de.jpg",
    category: "Produtividade e Gestão de Tempo",
    language: "Portuguese",
  },
  {
    _id: "67394cb17a4acc1e3b03a315",
    title: "Mindset: A Nova Psicologia do Sucesso",
    description:
      "Mindset: A Nova Psicologia do Sucesso de Carol S. Dweck explora a teoria do mindset, e como ela afeta o nosso sucesso na vida pessoal, acadêmica e profissional",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/jjNJGqnq/mindset.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "F8rrJdqpp1g",
  },
  {
    _id: "67394cb17a4acc1e3b03a316",
    title: "O Guia Oficial do TED para Falar em Público",
    description:
      "O Guia Oficial do TED para Falar em Público de Chris Anderson oferece dicas práticas para criar e apresentar palestras impactantes. Anderson destaca a importância de ter uma mensagem clara e focar em uma ideia central",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/8cr8bYnY/o-guia.jpg",
    category: "Liderança e Sucesso",
    language: "Portuguese",
    urlAudio: "v6gtP1N8uzk",
  },
  {
    _id: "67394cb17a4acc1e3b03a317",
    title: "Viver: A Que Se Destina?",
    description:
      "Viver: A Que Se Destina? de Mário Sérgio Cortella e Leandro Karnal é uma reflexão sobre o sentido da vida e o que realmente importa na existência humana",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/L4f2LYK8/viver-a.jpg",
    category: "Filosofia e Reflexão",
    language: "Portuguese",
  },
  {
    _id: "67394cb17a4acc1e3b03a318",
    title: "Pensamento Crítico: O Poder da Lógica e da Argumentação",
    description:
      "Pensamento Crítico: O Poder da Lógica e da Argumentação de Walter A. Carnielli ensina como melhorar o raciocínio lógico e a argumentação, aplicando o pensamento crítico no cotidiano",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/mrRHWfYQ/pensamento-cri.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
  },
  {
    _id: "67394cb17a4acc1e3b03a319",
    title:
      "Não Pergunte se Ele Estudou: Como Despertar nos Filhos o Interesse e a Motivação nos Estudos",
    description:
      "Não Pergunte se Ele Estudou: Como Despertar nos Filhos o Interesse e a Motivação nos Estudos de Renato Alves oferece estratégias para pais ajudarem seus filhos a se motivarem para os estudos de forma duradoura",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/13YD6Yw6/nao-perg.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
  },
  {
    _id: "67394cb17a4acc1e3b03a31a",
    title: "Escola de Negócios",
    description:
      "Escola de Negócios de Robert T. Kiyosaki é um livro que ensina como desenvolver habilidades empresariais e educação financeira prática para alcançar a independência financeira",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/6pDyBsCH/escola-de.jpg",
    category: "Empreendedorismo e Criação de Riqueza",
    language: "Portuguese",
    urlAudio: "YBdMTVEP7zY",
  },
  {
    _id: "673e6acfe47880990404c4e8",
    title: "Os Segredos que Vão Mudar Sua Vida",
    description:
      "Os Segredos que Vão Mudar Sua Vida de Napoleon Hill é um guia sobre como alcançar o sucesso pessoal e financeiro, baseado em 13 princípios fundamentais que ele descobriu ao estudar pessoas bem-sucedidas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/XYskYDqT/os-segredos.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "7by_t8fwAPI",
  },
  {
    _id: "673e6acfe47880990404c4ea",
    title:
      "Nós Queremos Que Você Fique Rico: Dois Bilionários, Uma Só Mensagem",
    description:
      "Nós Queremos Que Você Fique Rico, é um livro escrito por Donald Trump e Robert Kiyosaki que compartilham suas filosofias sobre como alcançar a riqueza e a independência financeira",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/X7V5BcR3/nos-quere.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "DQQFf3S2H9c",
  },
  {
    _id: "673e6acfe47880990404c4ed",
    title: "Muito Além da Velocidade da Luz",
    description:
      "Muito Além da Velocidade da Luz, de Marc Seifer, explora como conceitos avançados de física quântica, teoria das supercordas e consciência podem revelar novas formas de perceber a realidade, ultrapassando as limitações da física convencional",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/DzqjycVs/muito-alem.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c4ee",
    title: "O Milagre da Manhã",
    description:
      "O Milagre da Manhã, de Hal Elrod, é um livro motivacional que propõe uma rotina matinal transformadora para melhorar a vida pessoal e profissional",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/9F7GhQHw/o-milagre.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "BzubcEUCaB0",
  },
  {
    _id: "673e6acfe47880990404c4f0",
    title: "23 Hábitos Anti-Procrastinação",
    description:
      "23 Hábitos Anti-Procrastinação, de S.J. Scott oferece estratégias práticas para combater a procrastinação e aumentar a produtividade",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/d1xNmPkP/23-habitos.jpg",
    category: "Hábitos e Produtividade",
    language: "Portuguese",
    urlAudio: "AS9ryIxI90U",
  },
  {
    _id: "673e6acfe47880990404c4f5",
    title: "52 Maneiras de Ganhar Mais Dinheiro",
    description:
      "52 Maneiras de Ganhar Mais Dinheiro de Salles é um guia prático com ideias e estratégias focadas em aumentar a sua renda",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/2SWC3W8m/52-man.jpg",
    category: "Desenvolvimento da Memória e Aprendizado",
    language: "Portuguese",
    urlAudio: "7LPV5lKG8ho",
  },
  {
    _id: "673e6acfe47880990404c4f6",
    title: "60 Estratégias Práticas para Ganhar Mais Tempo",
    description:
      "60 Estratégias Práticas para Ganhar Mais Tempo, de Christian Barbosa oferece dicas e técnicas para melhorar a gestão do tempo e aumentar a produtividade",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/W4N84QMv/60-est.jpg",
    category: "Hábitos e Produtividade",
    language: "Portuguese",
    urlAudio: "rs4GZ2-Vq0M",
  },
  {
    _id: "673e6acfe47880990404c4fc",
    title:
      "A Árvore do Dinheiro: Guia para Cultivar a Sua Independência Financeira",
    description:
      "A Árvore do Dinheiro: Guia para Cultivar a Sua Independência Financeira de Jurandir Sell Macedo é um livro que ensina como alcançar a independência financeira por meio de práticas de educação financeira e investimentos inteligentes",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/L8FdX6CZ/a-arvore.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "hiTtMm7_fMs",
  },
  {
    _id: "673e6acfe47880990404c506",
    title: "A Fascinante Construção do Eu",
    description:
      "A Fascinante Construção do Eu, de Augusto Cury é um livro que explora os aspectos da psicologia humana e como a construção da identidade e do eu é fundamental para o desenvolvimento pessoal",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Ls2PnTSF/a-fascinante.jpg",
    category: "Liderança e Desenvolvimento Pessoal",
    language: "Portuguese",
    urlAudio: "NHjTaO8VFFE",
  },
  {
    _id: "673e6acfe47880990404c50d",
    title:
      "A Realização Espontânea do Desejo: Como Utilizar o Poder Infinito da Coincidência",
    description:
      "A Realização Espontânea do Desejo, de Deepak Chopra explora como podemos manifestar nossos desejos de forma natural ao nos alinhar com o universo",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Pxp7jstf/a-realizacao.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "Ed8i_KV8kvQ",
  },
  {
    _id: "673e6acfe47880990404c513",
    title: "Casamento Blindado",
    description:
      "Casamento Blindado, de Renato e Cristiane Cardoso é um livro focado em fortalecer relacionamentos matrimoniais, oferecendo orientações para casais superarem crises e construírem um relacionamento duradouro",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Jh9P5qFY/casamento-blin.jpg",
    category: "Relacionamentos e Casamentos",
    language: "Portuguese",
    urlAudio: "VZj8PSJ4WH8",
  },
  {
    _id: "673e6acfe47880990404c515",
    title:
      "Crer ou Não Crer: Uma Conversa Sem Rodeios Entre um Historiador Ateu e um Padre Católico",
    description:
      "Crer ou Não Crer: Uma Conversa Sem Rodeios Entre um Historiador Ateu e um Padre Católico, de Fábio de Melo é um diálogo aberto entre um historiador ateu e um padre católico, explorando temas como fé, razão, ciência, moralidade e a existência de Deus",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/BQkL6VPz/crer-ou.jpg",
    category: "Sociedade, Política e Cultura",
    language: "Portuguese",
    urlAudio: "0oLwt3YYiKc",
  },
  {
    _id: "673e6acfe47880990404c4ec",
    title: "O Poder do Silêncio",
    description:
      "O Poder do Silêncio, de Eckhart Tolle, explora a importância do silêncio interior como um meio para alcançar paz, clareza e conexão espiritual",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/qB8JVPZM/o-poder.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "M9pWzTmMUwU",
  },
  {
    _id: "673e6acfe47880990404c4f2",
    title: "A Sutil Arte de Ligar o Foda-se",
    description:
      "A Sutil Arte de Ligar o Foda-se, de Mark Manson é um livro que propõe uma abordagem honesta e irreverente sobre como lidar com os desafios da vida. Manson defende que a obsessão pela felicidade, perfeição e sucesso é uma fonte constante de sofrimento",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/L8JMw1ZL/a-sutil.jpg",
    category: "Motivação e Filosofia de Vida",
    language: "Portuguese",
    urlAudio: "k7moUWjb2Hk",
  },
  {
    _id: "673e6acfe47880990404c4f3",
    title:
      "28 Técnicas de Persuasão que Aumentam as Chances de Você Receber um Sim",
    description:
      "28 Técnicas de Persuasão que Aumentam as Chances de Você Receber um Sim, de Érico Rocha apresenta estratégias práticas para aumentar a eficácia da comunicação e influenciar decisões de forma ética",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/MGtbBvd3/28-tec.jpg",
    category: "Comunicação e Persuasão",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c500",
    title: "A Bíblia para o Milhão",
    description:
      "A Bíblia para o Milhão, de Eduardo César é um livro focado em ajudar as pessoas a alcançarem a independência financeira e a construírem riqueza de forma estratégica e consistente",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/65CxYVkP/a-biblia-para.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c501",
    title: "A Bola de Neve: Warren Buffett e o Negócio da Vida",
    description:
      "A Bola de Neve: Warren Buffett e o Negócio da Vida, de Alice Schroeder é uma biografia detalhada de Warren Buffett, um dos investidores mais bem-sucedidos do mundo",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/ZKSMYGQf/a-bola.jpg",
    category: "Histórias de Vida e Biografias",
    language: "Portuguese",
    urlAudio: "zwOw4_bFGh4",
  },
  {
    _id: "673e6acfe47880990404c503",
    title: "A Cabala e a Arte de Ser Feliz",
    description:
      "A Cabala e a Arte de Ser Feliz, de Ian Mecler conecta os ensinamentos da Cabala à busca pela felicidade. O livro propõe que a verdadeira felicidade vem do autoconhecimento e da conexão com o divino, não de fatores externos",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/X7WnMDkW/a-cabala-arte.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "JO9S6eIV9XM",
  },
  {
    _id: "673e6acfe47880990404c505",
    title: "A Carta de Vendas de 16 Palavras",
    description:
      "A Carta de Vendas de 16 Palavras, de Evaldo Albuquerque ensina como criar cartas de vendas curtas e eficazes usando apenas 16 palavras-chave estratégicas. O livro foca na importância da simplicidade, clareza e psicologia do consumidor",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/SKFrVCpg/a-carta16.jpg",
    category: "Comunicação e Persuasão",
    language: "Portuguese",
    urlAudio: "kwfIbJITFZs",
  },
  {
    _id: "673e6acfe47880990404c507",
    title:
      "A Física Quântica na Vida Real: Nas Atividades e nos Relacionamentos",
    description:
      "A Física Quântica na Vida Real: Nas Atividades e nos Relacionamentos, de Osny Ramos, explora como os conceitos da física quântica podem ser aplicados de forma prática no cotidiano, especialmente nas interações humanas e na transformação pessoal",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/6Q6dHXQm/a-fisica.png",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c508",
    title: "Conversas com Gestores de Ações Brasileiros",
    description:
      "Conversas com Gestores de Ações Brasileiros, de Luciana Sabra, reúne entrevistas com gestores de fundos de ações renomados no Brasil. O livro compartilha as estratégias de investimento desses profissionais",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/zX0DCZ9B/conversas-com.jpg",
    category: "Comunicação e Persuasão",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c512",
    title: "Como Fazer Dinheiro",
    description:
      "Como Fazer Dinheiro, de Tag Powell oferece orientações práticas e estratégias para aqueles que buscam entender os princípios do sucesso financeiro e a construção de riqueza",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/XJC1RhF0/como-fazer.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c514",
    title: "Como Ler Livros: O Guia Clássico para a Leitura Inteligente",
    description:
      "Como Ler Livros: O Guia Clássico para a Leitura Inteligente, de Mortimer J. Adler e Charles Van Doren ensina como ler de forma profunda e analítica, maximizando a compreensão de livros complexos",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/3JK91WLh/como-ler.jpg",
    category: "Desenvolvimento da Memória e Aprendizado",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c4e7",
    title: "Mini-Hábitos: Hábitos Menores, Maiores Resultados",
    description:
      "Mini-Hábitos: Hábitos Menores, Maiores Resultados de Stephen Guise propõe uma abordagem para criar mudanças duradouras a partir de ações mínimas e diárias",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/FR8PsCW9/mini-hab.jpg",
    category: "Hábitos e Produtividade",
    language: "Portuguese",
    urlAudio: "-dQXfvpwsRw",
  },
  {
    _id: "673e6acfe47880990404c4eb",
    title:
      "Os Princípios e o Poder da Visão: Chaves para Alcançar o Destino Pessoal e Corporativo",
    description:
      "Os Princípios e o Poder da Visão: Chaves para Alcançar o Destino Pessoal e Corporativo, de Myles Munroe, explora a importância de ter uma visão clara e alinhada com o propósito para alcançar o sucesso pessoal e organizacional",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Vkz5Vff8/os-principios.jpg",
    category: "Liderança e Desenvolvimento Pessoal",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c4ef",
    title: "12 Regras para a Vida: Um Antídoto para o Caos",
    description:
      "12 Regras para a Vida: Um Antídoto para o Caos, de Jordan Peterson é um livro que oferece princípios para viver de forma mais ordenada e significativa, em meio ao caos da vida moderna",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/c1hcfN3v/12-regras.jpg",
    category: "Hábitos e Produtividade",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c4f1",
    title: "29 Minutos para Falar Bem em Público e Conversar com Desenvoltura",
    description:
      "29 Minutos para Falar Bem em Público e Conversar com Desenvoltura é um guia prático e direto para quem deseja melhorar sua comunicação, seja em apresentações formais ou em conversas cotidianas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/vmB5b1TM/29-min.jpg",
    category: "Comunicação e Persuasão",
    language: "Portuguese",
    urlAudio: "hagJaoIhHiQ",
  },
  {
    _id: "673e6acfe47880990404c4f7",
    title: "100 Segredos das Pessoas Felizes",
    description:
      "100 Segredos das Pessoas Felizes, de David Niven apresenta uma série de insights baseados em pesquisas científicas e estudos de psicologia para ajudar as pessoas a alcançarem uma vida mais satisfatória e feliz",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/MH7fVGSr/os-100.jpg",
    category: "Desenvolvimento da Memória e Aprendizado",
    language: "Portuguese",
    urlAudio: "sO4JmLtg8f0",
  },
  {
    _id: "673e6acfe47880990404c4f8",
    title: "1984",
    description:
      "1984, de George Orwell é um romance distópico que descreve um futuro totalitário onde o Estado, representado pelo Partido e sua figura de autoridade, Big Brother, exerce controle absoluto sobre todos os aspectos da vida dos cidadãos",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/8PdnPdqL/1984.jpg",
    category: "Sociedade, Política e CulturaSociedade, Política e Cultura",
    language: "Portuguese",
    urlAudio: "g0pLnFt5rlo",
  },
  {
    _id: "673e6acfe47880990404c4f9",
    title:
      "A Arquitetura do Eu: Psicoterapia, Meditação e Exercícios para o Cérebro",
    description:
      "A Arquitetura do Eu: Psicoterapia, Meditação e Exercícios para o Cérebro de Leonardo Mascaro é uma obra que propõe uma abordagem integrada para o autoconhecimento e o desenvolvimento pessoal, combinando psicoterapia, meditação e neurociência",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Qt5JYhDc/a-arq.jpg",
    category: "Comunicação e Persuasão",
    language: "Portuguese",
    urlAudio: "NSioaOdIYds",
  },
  {
    _id: "673e6acfe47880990404c4fe",
    title: "A Bíblia do Marketing Digital",
    description:
      "A Bíblia do Marketing Digital, de Cláudio Torres é um livro que oferece um guia completo sobre o marketing digital, apresentando conceitos, estratégias e ferramentas essenciais para quem deseja trabalhar ou aprimorar suas habilidades nessa área",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/HLXY5Dpr/a-biblia.png",
    category: "Marketing e Vendas",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c4ff",
    title: "A Bíblia do Vendedor",
    description:
      "A Bíblia do Vendedor, de Stephan Schiffman é um guia prático e completo para profissionais de vendas, com o objetivo de ajudar a melhorar as habilidades de negociação e aumentar os resultados de vendas",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/9M1jtcsQ/biblia-do-vend.jpg",
    category: "Comunicação e Persuasão",
    language: "Portuguese",
    urlAudio: "w2O6ehmsAL8",
  },
  {
    _id: "673e6acfe47880990404c502",
    title: "A Cabala do Dinheiro",
    description:
      "A Cabala do Dinheiro, de Nilton Bonder é um livro que aborda a relação entre as pessoas e o dinheiro sob uma perspectiva filosófica e espiritual, com base nos ensinamentos da Cabala, uma tradição mística judaica",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/VLvbt625/a-cabala.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "ynO0Low9_To",
  },
  {
    _id: "673e6acfe47880990404c509",
    title: "A História da Ciência para Quem Tem Pressa",
    description:
      "A História da Ciência para Quem Tem Pressa, de Nicola Chalton, oferece um resumo conciso e acessível dos principais marcos da história da ciência, desde a Antiguidade até os tempos modernos",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/3RTHMnPV/a-historia.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c50a",
    title: "A Loja de Tudo: Jeff Bezos e a Era da Amazon",
    description:
      "A Loja de Tudo: Jeff Bezos e a Era da Amazon, de Brad Stone, é uma biografia detalhada sobre Jeff Bezos, o fundador da Amazon, e a história de como ele construiu uma das empresas mais poderosas e influentes do mundo",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/LXTwcRkg/a-loja.jpg",
    category: "Histórias de Vida e Biografias",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c50e",
    title: "A Tríade do Tempo",
    description:
      "A Tríade do Tempo, é um livro de Christian Barbosa que apresenta uma abordagem prática para gerenciar o tempo de maneira eficiente e produtiva",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/yx5YNzRf/a-triade.jpg",
    category: "Hábitos e Produtividade",
    language: "Portuguese",
    urlAudio: "dkh0BL8r6ZU",
  },
  {
    _id: "673e6acfe47880990404c511",
    title: "Decifre e Influencie Pessoas",
    description:
      "Decifre e Influencie Pessoas, de Paulo Vieira é uma obra voltada para o desenvolvimento pessoal e profissional, focada principalmente na comunicação interpessoal e no entendimento dos comportamentos humanos",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Wz0KkKb7/decifre-e.jpg",
    category: "Liderança e Desenvolvimento Pessoal",
    language: "Portuguese",
    urlAudio: "sTOcGbsE268",
  },
  {
    _id: "673e6acfe47880990404c4e5",
    title: "Os 5 Níveis de Liderança",
    description:
      "Os 5 Níveis de Liderança, conceito desenvolvido por John Maxwell, descrevem como a influência de um líder cresce à medida que ele avança em sua jornada de liderança",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/pTkGQfH3/os-5.jpg",
    category: "Liderança e Desenvolvimento Pessoal",
    language: "Portuguese",
    urlAudio: "mXPXbUUlBjU",
  },
  {
    _id: "673e6acfe47880990404c4e6",
    title:
      "Elon Musk: Como o CEO bilionário da SpaceX e da Tesla está moldando nosso futuro",
    description:
      "Elon Musk: Como o CEO bilionário da SpaceX e da Tesla está moldando nosso futuro de Ashlee Vance é uma biografia que detalha a vida, os desafios e as conquistas de Elon Musk, explorando como ele está moldando o futuro com suas empresas inovadoras",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/rpq5QHMs/elon-musk.jpg",
    category: "Histórias de Vida e Biografias",
    language: "Portuguese",
    urlAudio: "bzAvxlw5gKY",
  },
  {
    _id: "673e6acfe47880990404c4e9",
    title: "Os 10 Hábitos da Memorização",
    description:
      "Os 10 Hábitos da Memorização de Renato Alves apresenta estratégias para melhorar a memória e a aprendizagem",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/9027pGzd/os-10hab.jpg",
    category: "Desenvolvimento da Memória e Aprendizado",
    language: "Portuguese",
    urlAudio: "8M18YSEo1PE",
  },
  {
    _id: "673e6acfe47880990404c4f4",
    title: "50 Ideias de Economia que Você Precisa Conhecer",
    description:
      "50 Ideias de Economia que Você Precisa Conhecer, de Edmundo Conway apresenta uma série de conceitos e teorias fundamentais da economia de forma acessível e prática",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/DyMzSFrQ/50-ideias.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "CDzo7_DOMD4",
  },
  {
    _id: "673e6acfe47880990404c4fa",
    title: "A Arte da Não Conformidade",
    description:
      "A Arte da Não Conformidade, de Chris Guillebeau é um livro motivacional que inspira o leitor a seguir um caminho não convencional, desafiando as normas sociais e construindo uma vida autêntica e independente",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/nr9bPsz0/a-arte-da-nao.webp",
    category: "Liderança e Desenvolvimento Pessoal",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c4fb",
    title: "A Arte de Fazer Acontecer",
    description:
      "A Arte de Fazer Acontecer, GTD de David Allen é um método para melhorar a produtividade e reduzir o estresse, organizando tarefas de forma eficiente",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/W4Q0RCbj/arte-de-faz.jpg",
    category: "Desenvolvimento Pessoal e Psicologia",
    language: "Portuguese",
    urlAudio: "g0LOPkHFbbs",
  },
  {
    _id: "673e6acfe47880990404c4fd",
    title: "A Ascensão do Dinheiro: A História Financeira do Mundo",
    description:
      "A Ascensão do Dinheiro: A História Financeira do Mundo, de Niall Ferguson é um livro que examina a evolução das finanças ao longo da história e como elas moldaram o mundo moderno",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/4yKpbxn6/a-ascensao.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "cEiV0yVsMaQ",
  },
  {
    _id: "673e6acfe47880990404c504",
    title: "A Cabeça de Steve Jobs",
    description:
      "A Cabeça de Steve Jobs, de Leander Kahney explora a mentalidade e o estilo de liderança de Steve Jobs, cofundador da Apple. O livro destaca seu perfeccionismo, foco na simplicidade e design minimalista, bem como sua capacidade de pensar",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/hthwN96g/a-cabeca.jpg",
    category: "Histórias de Vida e Biografias",
    language: "Portuguese",
    urlAudio: "JFTCJIYJYEA",
  },
  {
    _id: "673e6acfe47880990404c50b",
    title: "A Máquina Definitiva de Vendas",
    description:
      "A Máquina Definitiva de Vendas, de Chet Holmes, é um livro focado em estratégias de vendas e marketing, que visa ajudar empresas a melhorar seus resultados por meio de abordagens práticas e eficazes",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/2629HGm4/a-maquina.webp",
    category: "Marketing e Vendas",
    language: "Portuguese",
  },
  {
    _id: "673e6acfe47880990404c50c",
    title: "A Psicologia Financeira",
    description:
      "A Psicologia Financeira, de Morgan Housel explora como as emoções e comportamentos influenciam as decisões financeiras, mais do que o conhecimento técnico",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/VNzZd0mh/a-psicologia.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "EAJERQFc0KQ",
  },
  {
    _id: "673e6acfe47880990404c50f",
    title: "O Investidor Inteligente",
    description:
      "O Investidor Inteligente, de Benjamin Graham é um guia clássico sobre investimentos, destacando a diferença entre investir e especular. Graham defende o investimento em valor, ou seja, comprar ativos subvalorizados com uma margem de segurança",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/Jh1yvM5J/o-investidor.jpg",
    category: "Finanças e Sucesso Financeiro",
    language: "Portuguese",
    urlAudio: "fMo_xntieUo",
  },
  {
    _id: "673e6acfe47880990404c510",
    title: "F*deu Geral: Um Livro Sobre Esperança",
    description:
      "F*deu Geral: Um Livro Sobre Esperança, de Mark Manson é uma obra que aborda temas como dificuldades, frustrações e desafios da vida de uma maneira honesta e irreverente",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/WpF1zh9S/fdeu-geral.jpg",
    category: "Motivação e Filosofia de Vida",
    language: "Portuguese",
    urlAudio: "r7Em5MI3CbQ",
  },
  {
    _id: "673e6acfe47880990404c516",
    title: "Negocie Como Se Sua Vida Dependesse Disso",
    description:
      "Negocie Como Se Sua Vida Dependesse Disso, de Chris Voss é um livro sobre técnicas de negociação desenvolvidas por Voss, um ex-agente do FBI, que se especializou em negociações de reféns",
    type: "Baixar",
    urlFile: "",
    urlImage: "https://i.postimg.cc/NFrtPpGy/negocie-como.jpg",
    category: "Comunicação e Persuasão",
    language: "Portuguese",
    urlAudio: "dZbwGfn27UQ",
  },
];
