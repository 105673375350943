import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { ThemeProvider } from "./hooks/theme";
import dark from "./styles/themes/Dark";
import light from "./styles/themes/Light"; // Assuming you have a light theme
import { TranslationProvider } from "./contexts/TranslationContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Main = () => {
  const [theme, setTheme] = useState(dark); // Start with dark theme

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === dark ? light : dark)); // Toggle between themes
  };

  return (
    <ThemeProvider theme={theme} toggleTheme={toggleTheme}>
      <TranslationProvider>
        <App />
      </TranslationProvider>
    </ThemeProvider>
  );
};

root.render(<Main />);
