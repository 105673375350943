import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Layout } from "../components/Layout/Layout";

import { AuthProvider } from "../contexts/AuthContexts";

import AllPage from "../pages/allEbooks";

import { TranslationProvider } from "../contexts/BooksContext";
import AudioBooks from "../pages/audioEbooks";

export function AppRoutes() {
  return (
    <TranslationProvider>
      {/* {!noHeaderRoutes.includes(location.pathname) && <Header />} */}
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            {/* Rotas privadas */}
            {/* <Route element={<PrivateRoute />}> */}
            {/* <Route
              path="/"
              element={
                <Layout>
                  <HomePage />
                </Layout>
              }
            /> */}
            {/* <Route
              path="/books-summary"
              element={
                <LayoutRead>
                  <HomePage />
                </LayoutRead>
              }
            />
            <Route
              path="/books-summary/:id"
              element={
                <LayoutBook>
                  <BookPage />
                </LayoutBook>
              }
            /> */}
            {/* </Route> */}
            <Route
              path="/"
              element={
                <Layout>
                  <AllPage />
                </Layout>
              }
            />
            <Route
              path="/books-audios"
              element={
                <Layout>
                  <AudioBooks />
                </Layout>
              }
            />
            {/* <Route
              path="/books-audios/:id/:urlAudio"
              element={
                <LayoutBook>
                  <BookPageAudio />
                </LayoutBook>
              }
            /> */}

            {/* <Route
              path="/cadastrar"
              element={
                <Layout>
                  <BookForm />
                </Layout>
              }
            /> */}
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </TranslationProvider>
  );
}
