// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title: "dark",

  colors: {
    primary: "#0f0f0f",
    secondary: "#0b0c0b",
    tertiary: "#ff0000",

    font: "#e4e4e4",
    white: "#2b2e2f",
    black: "#181A1B",
    gray: "#dfdfdf",

    success: "#03BB85",
    info: "#0086d2",
    warning: "#E44C4E",
  },
};
