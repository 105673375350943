import {
  Container,
  BannerImage,
  TextOverlay,
  OverlayBackground,
} from "./styles";

import { ImDownload } from "react-icons/im";
import { TiInfoLarge } from "react-icons/ti";

export const BannerBaixar = () => {
  return (
    <Container>
      <BannerImage
        src="libros-capa.jpg"
        alt="Melhores Livros de Desenvolvimento Pessoal"
        loading="lazy"
      />

      <OverlayBackground />
      <TextOverlay>
        <ImDownload className="icon" />

        <h1>Baixar</h1>

        <p>Baixe seus livros favoritos e leia quando e onde quiser! </p>

        <span className="obs">
          <strong>
            <TiInfoLarge className="icon-info" />
            <h4> AVISO! </h4>
          </strong>
          <p className="Info-des">
            Libere a plataforma e tenha acesso a todas funcionalidades.
          </p>
          {/* <p className="Info-des">
            A plataforma está em desenvolvimento e trará novas melhorias nas
            próximas versões
          </p> */}
        </span>
      </TextOverlay>
    </Container>
  );
};
