import { DetailedHTMLProps, HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface ContentProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flexDirection?: "row" | "column";
  flexWrap?: "wrap" | "nowrap";
}

export const Content = styled.div<ContentProps>`
  display: flex;
  width: 88%;
  margin: auto;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
`;

export const Button = styled.button`
  padding: 1rem 2rem;
  border: none;
  background: #3b0385;
  color: #fff;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  font-size: 1rem;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
  margin-bottom: 1rem;
`;
