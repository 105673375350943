import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  margin: auto;
  font-family: "Montserrat", sans-serif;
  flex-direction: column;

  .border-header {
    position: relative;
    width: 100%;
    bottom: 0;
    z-index: 1000;
  }
  .border-header::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      to right,
      transparent,
      red 50%,
      transparent 90%
    );
  }
`;

export const Title = styled.h1`
  font-size: 2.5em;
  color: ${(props) => props.theme.colors.font};
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  width: 89%;
  margin: 1.4rem auto;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  /* background: red; */

  @media (max-width: 1200px) {
    font-size: 1rem;
    margin-bottom: 1px;
  }
`;

export const Content = styled.div`
  width: 99%;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 3rem 0;
  margin: auto;
  height: 100%;
  @media (max-width: 1208px) {
    padding-top: 2rem;
    width: 100%;
  }
`;
export const Videos = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  position: relative;
  align-items: center;
  gap: 2rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* Para Firefox */
  -ms-overflow-style: none; /* Para IE e Edge */

  &::-webkit-scrollbar {
    display: none; /* Esconde a barra de rolagem */
  }

  /* Estilos das setas */
  .left,
  .right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4rem;
    color: ${(props) => props.theme.colors.tertiary};
    cursor: pointer;
    z-index: 1;
  }

  .left {
    left: 0; /* Fixa a seta à esquerda */
  }

  .right {
    right: 0; /* Fixa a seta à direita */
  }

  @media (max-width: 1200px) {
    .right,
    .left {
      display: none;
    }
  }
`;

export const Video = styled.div`
  display: flex;
  width: 88%;

  margin: auto;
  flex-direction: row;
  overflow-x: auto; // Permitir a rolagem
  scroll-behavior: smooth; // Suavizar a rolagem
  scrollbar-width: none; /* Para Firefox */
  -ms-overflow-style: none;
`;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const ContentHeader = styled.div`
  text-align: start;
  font-size: 2.3rem;
  flex-direction: row;
  width: 50%;
  @media (max-width: 1200px) {
    font-size: 1.2rem;
    width: 90%;
    text-align: center;
  }
`;

export const PlayButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;
  @media (max-width: 1208px) {
    margin: auto;
    align-items: center;
    width: 100%;
  }
`;

export const CardVideo = styled.div`
  background: #232323;
`;
export const Loading = styled.div`
  width: 100%;
  height: 100vh;

  justify-content: center;
  align-items: center;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  p {
    color: ${(props) => props.theme.colors.font};
  }
`;
