import React, { useState } from "react";
import { useTranslation } from "../../contexts/BooksContext";

import {
  SelectorContainer,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  FlagImage,
} from "./styles";
import { Livro } from "../../pages/allEbooks";
import BasicModal from "../modal/Modal";

type Language = "Portuguese" | "English" | "Spanish";

const LanguageSelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [books, setBooks] = useState<Livro[]>([]);

  const { selectedLanguage, setLanguage } = useTranslation();

  // Idiomas disponíveis
  const languages = [
    { value: "Portuguese", label: "Português", flag: "/brasil.png" },
    { value: "English", label: "English", flag: "/ingles.png" },
    { value: "Spanish", label: "Español", flag: "/espanhol.png" },
  ];

  const handleSelect = (value: Language) => {
    setLanguage(value);
    setIsOpen(false);
    setLanguage(value);
  };

  const selectedLanguageData = languages.find(
    (lang) => lang.value === selectedLanguage
  );
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <SelectorContainer>
      <BasicModal
        open={openModal}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
      />

      <DropdownButton onClick={handleOpenModal}>
        {selectedLanguageData && (
          <FlagImage
            src={selectedLanguageData.flag}
            alt={selectedLanguageData.label}
          />
        )}{" "}
        {selectedLanguageData?.value}
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {languages?.map((lang) => (
            <DropdownItem
              key={lang.value}
              onClick={() => handleSelect(lang.value as Language)}
            >
              <FlagImage src={lang.flag} alt={lang.label} />
              {lang?.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </SelectorContainer>
  );
};

export default LanguageSelector;
