// src/components/LanguageSelector/styles.ts
import styled from "styled-components";

export const SelectorContainer = styled.div`
  position: relative;
  display: flex;
  margin-right: 2rem;
  font-family: "Poppins", sans-serif;

  @media (max-width: 768px) {
    margin-right: 0rem;
    margin-left: 2rem;
  }
`;

export const DropdownButton = styled.div`
  padding: 10px;
  border: 1px solid #ccc;

  border-radius: 20px;
  cursor: pointer;
  /* background-color: white; */
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const DropdownItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const FlagImage = styled.img`
  width: 20px;
  margin-right: 8px;
`;
