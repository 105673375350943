import React, { createContext, useContext, useState } from "react";

type Language = "Portuguese" | "English" | "Spanish";

interface TranslationContextProps {
  selectedLanguage: Language;
  setLanguage: (language: Language) => void;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(
  undefined
);

export const TranslationProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [selectedLanguage, setSelectedLanguage] =
    useState<Language>("Portuguese");

  const setLanguage = (language: Language) => {
    setSelectedLanguage(language);
  };

  return (
    <TranslationContext.Provider value={{ selectedLanguage, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }
  return context;
};
