import { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type AuthContextData = {
  user?: UserProps;
  autheticated: boolean;
  loading: boolean;

  value?: any;
};

type UserProps = {
  id: string;
  name: string;
  email: string;
};

export type SignInProps = {
  email: string;
  password: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

type SignUpProps = {
  name: string;
  email: string;
  password: string;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [autheticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<UserProps>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  return (
    <AuthContext.Provider value={{ user, autheticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
}
