import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import BarraPesquisa from "../../components/search/BookSearch";
import BookAudio from "../../components/bookAudio";
import { BannerAudio } from "../../components/BannerAudio";
import ClipLoader from "react-spinners/ClipLoader";
import { Livro } from "../allEbooks";
import { LivroPt } from "../../data/livrosPt";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "../book/styles";
import { IoArrowDown } from "react-icons/io5";
import BasicModal from "../../components/modal/Modal";

interface BookAudioProps {
  playAgora?: (id: string) => void;
}

const AudioBooks = ({ playAgora }: BookAudioProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [audioUrl, setUrlAudio] = useState<Livro[]>(LivroPt);
  const [filteredBooks, setFilteredBooks] = useState<Livro[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(1);
  const booksPerPage = 8;

  const resources = "Todos os livros para Ouvir";

  const handleSearchBooks = (query: string) => {
    setSearchQuery(query);
    const filtered = audioUrl.filter(
      (book) =>
        book.title.toLowerCase().includes(query.toLowerCase()) ||
        book.description?.toLowerCase().includes(query.toLowerCase()) ||
        book.category.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredBooks(filtered.slice(0, booksPerPage));
  };

  useEffect(() => {
    if (searchQuery) {
      setLoading(true);
      handleSearchBooks(searchQuery);
      setLoading(false);
    } else {
      setLoading(true);
      setFilteredBooks(LivroPt);
      setLoading(false);
    }
  }, [searchQuery]);

  const loadMoreBooks = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const paginatedBooks = useMemo(() => {
    const startIndex = (page - 1) * booksPerPage;
    const endIndex = page * booksPerPage;
    return filteredBooks.slice(0, endIndex);
  }, [page, filteredBooks]);

  const hasMoreBooks = paginatedBooks.length < filteredBooks.length;

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <S.Container>
      <div className="border-header"></div>
      <BannerAudio />
      <BasicModal
        open={openModal}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
      />
      <S.Content id="cards">
        <S.Title>
          <S.ContentTitle>
            <S.ContentHeader>{resources}</S.ContentHeader>
            <BarraPesquisa onSearch={handleSearchBooks} />
          </S.ContentTitle>
        </S.Title>

        {loading ? (
          <S.Loading className="loading-spinner">
            <ClipLoader color="#36d7b7" loading={loading} size={50} />
            <p> Carregando livros...</p>
          </S.Loading>
        ) : (
          <InfiniteScroll
            dataLength={paginatedBooks.length}
            next={loadMoreBooks}
            hasMore={hasMoreBooks}
            loader={<p>Carregando mais livros...</p>}
            endMessage={
              <div
                style={{
                  margin: "1rem auto",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button onClick={handleOpenModal}>
                  <IoArrowDown style={{ marginBottom: "-3px" }} /> Mostrar mais
                  audiosBooks <IoArrowDown style={{ marginBottom: "-3px" }} />
                </Button>
              </div>
            }
            scrollThreshold={0.95}
          >
            <S.PlayButton>
              <BookAudio
                livros={paginatedBooks}
                handleLerAgora={handleOpenModal}
                showArrows={false}
                flexWrap="wrap"
              />
            </S.PlayButton>
          </InfiniteScroll>
        )}
      </S.Content>
    </S.Container>
  );
};

export default AudioBooks;
