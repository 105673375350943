import React, { useState } from "react";
import { Container, Content } from "./styles";
import BookCardAudio from "../BookCardAudio/BookCardAudio";
import { Livro } from "../../pages/allEbooks";
import BasicModal from "../modal/Modal";

interface BookCarouselProps {
  livros: Livro[];
  handleLerAgora: (id: string, urlAudio: string) => void;
  showArrows?: boolean;
  flexWrap?: "wrap" | "nowrap";
}

const BookAudio: React.FC<BookCarouselProps> = ({
  livros,
  handleLerAgora,
  flexWrap,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  return (
    <Container>
      <BasicModal
        open={openModal}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
      />
      <Content flexWrap={flexWrap}>
        {livros?.map((livro) => (
          <BookCardAudio
            key={livro?._id}
            urlImage={livro?.urlImage}
            title={livro?.title}
            subtitle={livro?.subtitle || ""}
            description={livro?.description}
            tipo={"download"}
            onAcao={(id: string, urlAudio: string) => {
              handleLerAgora(id, urlAudio);
            }}
            _id={livro?._id}
            type={"Play"}
            urlFile={""}
            category={livro?.category}
            language={livro?.language}
            urlAudio={livro?.urlAudio || ""}
          />
        ))}
      </Content>
    </Container>
  );
};

export default BookAudio;
