import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  // border: "2px solid #000",
  boxShadow: 24,
  padding: "0px",
  m: 0,
  borderRadius: "20px",
};

interface BasicModalProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}
export default function BasicModal({
  open,
  handleOpen,
  handleClose,
}: BasicModalProps) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 0,
              p: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <section className="buy">
              <div className="card-buy" id="buy">
                <div className="cards-buy card-effect">
                  <h2 className="card-title-buy">
                    Tenha acessos a toda plataforma com todos e-books,
                    audiosBooks e muito mais por apenas <br />
                    {/* <strong className="style-red">Netflix de Livros</strong> */}
                  </h2>
                  <ul className="card-des-buy">
                    <li>
                      <i className="fa-solid fa-circle-check card-icon"></i>
                      <span>Acesso a uma ampla biblioteca</span>
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check card-icon"></i>{" "}
                      AudioBooks
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check card-icon"></i>{" "}
                      Livros em Inglês e Espanhol
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check card-icon"></i>
                      Tradução do resumos em inglês e espanhol
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check card-icon"></i>{" "}
                      Chance de se tornar afiliado
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check card-icon"></i> Ler
                      offline
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check card-icon"></i>
                      <strong className="strong-li">
                        Esse valor libera todos os e-books, com acesso total da
                        plataforma
                      </strong>
                    </li>
                  </ul>
                  <div className="preco">
                    <span className="preco-original">de R$ 49,99</span>
                    <span className="preco-promocao">
                      <span className="por">Por</span>
                      R$ 9,99
                    </span>

                    <p>Pagamento único acesso a todos e-books</p>
                    <p>Acesso vitalício</p>
                  </div>
                  <a
                    href="https://pay.kiwify.com.br/MnBYOz5"
                    className="header-button btn-buy card-effect"
                  >
                    LIBERAR PLATAFORMA
                  </a>
                </div>
              </div>
            </section>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
