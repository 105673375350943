// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title: "light",

  colors: {
    primary: "#e0e0e0",
    secondary: "#fbfbfb",
    tertiary: "#ff0000",

    font: "#383944",
    white: "#dcdcdc",
    black: "#f3f3f2",
    gray: "#323232",

    success: "#03BB85",
    info: "#3B5998",
    warning: "#E44C4E",
  },
};
