import * as S from "./styles";
import Book from "../book";
import { useEffect, useState, useMemo } from "react";
import BarraPesquisa from "../../components/search/BookSearch";
import { BannerBaixar } from "../../components/BannerBaixar";
import ClipLoader from "react-spinners/ClipLoader";
import { LivroPt } from "../../data/livrosPt";
import BasicModal from "../../components/modal/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "../book/styles";
import { IoArrowDown } from "react-icons/io5";

export interface Livro {
  urlAudio?: string;
  _id: string;
  title: string;
  subtitle?: string;
  description: string;
  type: string;
  urlFile: string;
  urlImage: string;
  category: string;
  language: string;
}

const AllPage = () => {
  const [filteredBooks, setFilteredBooks] = useState<Livro[]>(LivroPt);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const booksPerPage = 8;

  const resources = "Todos os livros para Baixar";

  const handleSearchBooks = (query: string) => {
    setSearchQuery(query);
    const normalizedQuery = query.trim().toLowerCase();
    const filtered = LivroPt.filter((book) =>
      [book.title, book.description, book.category]
        .filter(Boolean)
        .some((field) => field.toLowerCase().includes(normalizedQuery))
    );
    setFilteredBooks(filtered);
  };

  useEffect(() => {
    if (searchQuery) {
      setLoading(true);
      handleSearchBooks(searchQuery);
      setLoading(false);
    } else {
      setLoading(true);
      setFilteredBooks(LivroPt);
      setLoading(false);
    }
  }, [searchQuery]);

  const loadMoreBooks = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const paginatedBooks = useMemo(() => {
    const startIndex = (page - 1) * booksPerPage;
    const endIndex = page * booksPerPage;
    return filteredBooks.slice(0, endIndex);
  }, [page, filteredBooks]);

  const hasMoreBooks = paginatedBooks.length < filteredBooks.length;

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <S.Container>
      <div className="border-header"></div>
      <BannerBaixar />

      <BasicModal
        open={openModal}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
      />
      <S.Content id="cards">
        <S.Title>
          <S.ContentTitle>
            <S.ContentHeader>{resources}</S.ContentHeader>
            <BarraPesquisa onSearch={handleSearchBooks} />
          </S.ContentTitle>
        </S.Title>

        <S.ContentBook>
          {loading ? (
            <S.Loading className="loading-spinner">
              <ClipLoader color="#36d7b7" loading={loading} size={50} />
              <p> Carregando livros...</p>
            </S.Loading>
          ) : (
            <InfiniteScroll
              dataLength={paginatedBooks.length}
              next={loadMoreBooks}
              hasMore={hasMoreBooks}
              loader={<p>Carregando mais livros...</p>}
              endMessage={
                <div
                  style={{
                    margin: "1rem auto",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button onClick={handleOpenModal}>
                    <IoArrowDown style={{ marginBottom: "-3px" }} /> Mostrar
                    mais livros <IoArrowDown style={{ marginBottom: "-3px" }} />
                  </Button>
                </div>
              }
              scrollThreshold={0.95}
            >
              <S.CBook>
                <Book
                  livros={paginatedBooks}
                  handleLerAgora={handleOpenModal}
                  handleBaixar={handleOpenModal}
                  showArrows={false}
                  flexWrap="wrap"
                />
              </S.CBook>
            </InfiniteScroll>
          )}
        </S.ContentBook>
      </S.Content>
    </S.Container>
  );
};

export default AllPage;
