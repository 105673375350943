// src/contexts/TranslationContext.tsx

import React, { createContext, useContext, useState, ReactNode } from "react";

type Language = "pt" | "en" | "es";

interface TranslationContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
}

const TranslationContext = createContext<TranslationContextType | undefined>(
  undefined
);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>("pt"); // Define o idioma inicial como "pt"

  return (
    <TranslationContext.Provider value={{ language, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslations = (): TranslationContextType => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error(
      "useTranslation deve ser usado dentro de um TranslationProvider"
    );
  }
  return context;
};
